import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Sidebar from "components/Layout/Sidebar"
// Models
import { IUser } from "models/User"
import { Link } from "gatsby"

type UserSidebarProps = {
  user: IUser
  translationsCount: number
  articlesCount: number
}

const UserSidebar: React.FC<UserSidebarProps> = ({ user, translationsCount, articlesCount }) => {
  const userAvatar = getImage(user.avatar.childImageSharp.gatsbyImageData)
  return (
    <Sidebar>
      <h2 className="title">Usuario</h2>
      <section className={"sidebar_featured"}>
        {userAvatar && <GatsbyImage className={"sidebar_featured__avatar"} image={userAvatar} alt={user.name} />}
        <div className={"sidebar_featured__info"}>
          <h3 className={"sidebar_featured__name"}>{user.name}</h3>
          <div className={"sidebar_featured__stats"}>
            {!!translationsCount && (
              <Link to={`/users/${user.slug}/translations`}>Traducciones: {translationsCount}</Link>
            )}
            {!!articlesCount && <Link to={`/users/${user.slug}/articles`}>Articulos: {articlesCount}</Link>}
          </div>
        </div>
        <div className={"sidebar_featured__social"}>
          {user.youtube && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`${user.youtube}`}
              aria-label="Youtube"
            >
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            </a>
          )}
          {user.spotify && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`${user.spotify}`}
              aria-label="Spotify"
            >
              <FontAwesomeIcon icon={["fab", "spotify"]} />
            </a>
          )}
          {user.twitter && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://twitter.com/${user.twitter}`}
              aria-label="Twitter"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
          )}
          {user.instagram && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://instagram.com/${user.instagram}`}
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          )}
          {user.facebook && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://fb.me/${user.facebook}`}
              aria-label="Facebook"
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
          )}
          {user.telegram && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://t.me/${user.telegram}`}
              aria-label="Telegram"
            >
              <FontAwesomeIcon icon={["fab", "telegram"]} />
            </a>
          )}
        </div>
        <p className={"sidebar_card_alt"}>{user.description}</p>
      </section>
    </Sidebar>
  )
}

export default UserSidebar
