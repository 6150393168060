import * as React from "react";
import { graphql, PageProps } from "gatsby";

// Components
import Layout from "components/Layout";
import Main from "components/Layout/Main";
import UserMenu from "components/Sections/User/UserMenu";
import UserSidebar from "components/Sections/User/UserSidebar";
import ArticleItem from "components/Sections/Articles/ArticleItem";
import Pagination from "components/UIElements/Pagination";
import Note from "components/UIElements/Note";
// Models
import { IUser } from "models/User";
import { IArticle } from "models/Article";
import { IOptionalMetaProps } from "models/Seo";

type DataProps = {
  user: IUser;
  translations: {
    count: number;
  };
  articles: {
    nodes: IArticle[];
    count: number;
  };
};

type PageContextProps = {
  currentPage: number;
  totalPages: number;
};

const UserPage: React.FC<PageProps<DataProps, PageContextProps>> = ({
  data,
  pageContext,
}) => {
  const { currentPage, totalPages } = pageContext;
  const { user, translations, articles } = data;

  const seo: IOptionalMetaProps = {
    title: `Artículos de ${user.name}`,
    author: { name: user.name, twitter: user.twitter },
    description: `Artículos publicados por ${user.name}${
      user.description &&
      `. Información sobre ${user.name}: ${user.description}`
    }.`,
    image: user.avatar.url,
  };

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <h2 className="title">Artículos de {user.name}</h2>
        <UserMenu slug={user.slug} hasTranslations={!!translations.count} />
        <div>
          {articles.nodes.length > 0 ? (
            articles.nodes.map((article) => (
              <ArticleItem key={article.fields.slug} article={article} />
            ))
          ) : (
            <Note>El usuario no ha escrito ningún artículo</Note>
          )}
        </div>
        <Pagination
          url={`/users/${user.slug}/articles`}
          title="Artículos"
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Main>
      <UserSidebar
        translationsCount={translations.count}
        articlesCount={articles.count}
        user={user}
      />
    </Layout>
  );
};

export default UserPage;

export const pageQuery = graphql`
  query UserArticlesPage($slug: String!, $limit: Int!, $skip: Int!) {
    user: user(slug: { eq: $slug }) {
      name
      slug
      description
      twitter
      facebook
      instagram
      youtube
      spotify
      telegram
      avatar {
        url
        childImageSharp {
          gatsbyImageData(
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }

    translations: allMdx(
      filter: {
        frontmatter: { translator: { eq: $slug } }
        fields: { collection: { eq: "songs" } }
      }
    ) {
      count: totalCount
    }

    articles: allMdx(
      filter: {
        frontmatter: { writer: { eq: $slug }, tags: { ne: "WIP" } }
        fields: { collection: { eq: "articles" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      count: totalCount
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          date(formatString: "DD [de] MMMM, YYYY", locale: "es")
          writer
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 530
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
