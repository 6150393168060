import * as React from "react"
import { Link } from "gatsby"

// Styles
import * as styles from "./UserMenu.module.css"

type UserMenuProps = {
  slug: string
  hasTranslations: boolean
}

const UserMenu: React.FC<UserMenuProps> = ({ slug, hasTranslations }) => {
  if (hasTranslations)
    return (
      <>
        <nav className={styles.user__nav}>
          <Link
            partiallyActive
            activeClassName={styles.selected}
            className={styles.user__nav_option}
            to={`/users/${slug}/articles`}
          >
            Artículos
          </Link>
          <Link
            partiallyActive
            activeClassName={styles.selected}
            className={styles.user__nav_option}
            to={`/users/${slug}/translations`}
          >
            Traducciones
          </Link>
        </nav>
      </>
    )
  return (
    <>
      <nav className={styles.user__nav}>
        <Link
          partiallyActive
          activeClassName={styles.selected}
          className={styles.user__nav_option}
          to={`/users/${slug}/articles`}
        >
          Artículos
        </Link>
        <div className={styles.user__nav_option_disabled}>Traducciones</div>
      </nav>
    </>
  )
}

export default UserMenu
